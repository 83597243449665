import * as React from 'react'
import { Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import { Pagination } from '../utils/pagination'

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Image from 'gatsby-image';

import translate from '../utils/translator'

export const Restaurant = ({ restaurant }) => {
  if (!restaurant) return null
  console.log('Restaurant : ', restaurant)

  const {type, uid, data, url, isOpen} = restaurant
  const detail = data.body.find(item => {
    return item.slice_type === 'restaurant_detail'
  })
  if (!detail) return null
  console.log('rest detail: ', detail)
  const {items, primary} = detail
  const {rest_name, address, category} = primary
  const {description, logo} = primary
  const firstImgItem = items[0]
  let image = ""
  if (firstImgItem && firstImgItem.image && firstImgItem.image.url) {
    image = firstImgItem.image.url
  }
  let imageAuto = null
  if (firstImgItem && firstImgItem.image) {
    imageAuto = firstImgItem.image
  }

  const link = `/restaurant/${uid}`

  const {
    american,
    asian,
    bar,
    german,
    greek,
    indian,
    international,
    italian,
    portuguese,
    street_food,
    sushi,
    turkish,
    vegan
  } = primary

  const firstParagraph = (description) => {
    // // Find the first text slice of post's body
    if (description != null) {
      // Set the character limit for the text we'll show in the homepage
      const textLimit = 60
      const text = description.text
      const limitedText = text.substring(0, textLimit)
  
      if (text.length > textLimit) {
        // Cut only up to the last word and attach '...' for readability
        return (
          <>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</>
        )
      }
      // If it's shorter than the limit, just show it normally
      return <>{text}</>
    }
    // If there are no slices of type 'text', return nothing
    return null
  }

  return (
    <div className="overflow-hidden rounded-3 bg-white border border-1 border-light h-100 d-flex flex-column">
      <a href={link} className="bg-dark position-relative" style={{height:"240px"}}>
        {/* {image && <img className="image-cover" src={image} />} */}
        {/* <GatsbyImage srcSet={imageAuto.fluid.srcSet} alt={'auto scaled image'} className="image-cover" /> */}
        {imageAuto && <Image fluid={imageAuto.fluid} className="image-cover" alt={rest_name + " Titelfoto"} />}
        <img className="position-absolute rounded-circle bg-white" style={{width:"48px",height:"48px",objectFit:"cover",bottom:"24px",right:"24px",outline:"2px solid rgba(255, 255, 255, .5)"}} src={logo.url ? logo.url : "/default-rest-logo.png"} alt={rest_name + " Logo"}/>
      </a>
      <div className="d-flex flex-fill flex-column justify-content-between">
        <div className="p-4 py-3 border-bottom flex-fill">
          <a className="text-decoration-none text-body" href={link}><h5>{rest_name}</h5></a>
          <div className="row gx-3 gy-1 fw-600 fs-0875">
            {american &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=american`}>Amerikanisch</a></div>
            }
            {asian &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=asian`}>Asiatisch</a></div>
            }
            {bar &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=bar`}>Bar</a></div>
            }
            {german &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=german`}>Deutsch</a></div>
            }
            {greek &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=greek`}>Griechisch</a></div>
            }
            {indian &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=indian`}>Indisch</a></div>
            }
            {international &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=international`}>International</a></div>
            }
            {italian &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=italian`}>Italienisch</a></div>
            }
            {portuguese &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=portuguese`}>Portugiesisch</a></div>
            }
            {street_food &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=street_food`}>Street Food</a></div>
            }
            {sushi &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=sushi`}>Sushi</a></div>
            }
            {turkish &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=turkish`}>Türkisch</a></div>
            }
            {vegan &&
			      	<div className="col-auto"><a className="text-decoration-none text-secondary" href={`/search/?categories=vegan`}>Vegan</a></div>
            }
          </div>
          <a href={link} className="mt-2 text-decoration-none text-secondary fs-0875">{firstParagraph(description)}</a>
        </div>
        <div className="p-4 py-2 d-flex align-items-center">
          {address &&
            <><img className="me-1 details-icon" height="16" width="16" src={`/icons/location.svg`} alt="Restaurantstandort Icon"/><a href={link} className="fs-075 text-truncate text-decoration-none text-body">{address}</a></>
          }
          <div className="ms-auto d-flex">
            <div className="vr"></div>
            <a href={link} className="d-flex align-items-center text-decoration-none text-body">
		    	  	<div className={"text-success bg-opacity-25 d-flex justify-content-center align-items-center me-2 " + (isOpen ? "bg-success" : "bg-danger")} style={{height:"26px", width:"26px",  borderRadius:"40%"}}>
                <img className={isOpen ? "schedule-open-icon" : "schedule-closed-icon"} height="20" width="20" src={`/icons/schedule.svg`} alt="Restaurant Status Icon"/>
		    	  	</div>
		    	  	<div className="fs-0875">{isOpen ? translate('schedule_open') : translate('schedule_closed')}</div>
		    	  </a>
          </div>
        </div>
      </div>
    </div>
  )
}
